import React, {
  useState,
} from 'react';

import {
  Spacer,
  ChakraProvider,
  Box,
  Flex,
  Text,
  Center
} from '@chakra-ui/react'

import Header from './components/Header';
import Footer from './components/Footer';
import Player from './components/Player';
import Connector from './components/Connector';
import SongList from './components/SongList';

const App = () => {

  const [token, setToken] = useState("")
  const [songs, setSongs] = useState([])
  const [searchKey, setSearchKey] = useState("")
  const [activeSongs, setActiveSongs] = useState([])

  return (
    <ChakraProvider>
      <Header
        token={token}
        setToken={setToken} />
      {token &&
        <Flex h='90vh' bg={'blackAlpha.900'}>
          <Box w='20vw'>
            <SongList
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              songs={songs}
              setSongs={setSongs}
              token={token}
              activeSongs={activeSongs}
              setActiveSongs={setActiveSongs}
            />
          </Box>
          <Box w='80vw'>
            <Connector
              token={token}
              activeSongs={activeSongs} />
          </Box>
        </Flex>
      }
      {!token &&
        <Flex h='90vh' w='100vw'>
          <Spacer />
          <Center>
            <Text fontSize={'3xl'}>Login to Spotify to start using the app!</Text>
          </Center>
          <Spacer />
        </Flex>
      }
      <Footer />
    </ChakraProvider>
  );
};


export default App;
