import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
    Box,
    Flex,
    Image,
    Text
} from '@chakra-ui/react'

const getSongData = async (token, id) => {
    const data = await axios.get("https://api.spotify.com/v1/tracks/" + id, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return data
}

const SongCard = (props) => {
    const [data, setData] = useState([])
    const [isLoading, setLoading] = useState(true)

    console.log(props)
    useEffect(() => {
        // Fetch the data
        getSongData(
            props.token,
            props.id
        ).then(spotifyData => {
            setData(spotifyData.data);
            setLoading(false)
        })
    }, [props.id, props.token]);

    return (
        <Box
            w='100%'
            key={props.id}
            id={'conn_' + props.id}
            marginBottom={3}
            borderRadius={10}
            color={'blackAlpha.900'}
            border={'1px solid white'}
            bg={'whitesmoke'}
        >
            {!isLoading &&
            <Flex p={2}>
                <Image
                    borderRadius={5}
                    boxSize='50px'
                    alt="SoundzLike.com"
                    fallbackSrc='https://via.placeholder.com/70'
                    src={data.album.images.length
                        ?
                        data.album.images[0].url
                        :
                        ''
                    }
                />
                <Box marginLeft={5} flex='1'>
                    <Text fontSize='sm'>
                        {data.artists[0].name} - {data.name}
                    </Text>
                    <Text fontSize='xs'>
                        {data.album.name}
                    </Text>
                </Box>
            </Flex>
            }
        </Box>
    )

}
export default SongCard;