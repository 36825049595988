import React from 'react';
import { Flex, Spacer, Center, Text } from '@chakra-ui/react'

const footerStyle = {
    position: 'relative',
    height: '5vh',
    background: 'green',
    display: 'flex',
}

const Footer = () => {
    // return(<Player/>)
    return(
      <Flex height={'calc(10vh - 80px)'} bg={'teal'} color={'white'}>
        <Spacer />
        <Center>
        <Text>SoundzLike.com © hexifox 2023</Text>
        </Center>
        <Spacer />
      </Flex>
    )
}

export default Footer;