import React, { useEffect, useState } from 'react';
import Xarrow from 'react-xarrows';
import axios from 'axios';

import {
  Container,
  Spacer,
  Center,
  Flex,
  Spinner,
} from '@chakra-ui/react'

import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore"

import { firestore as db } from './db/firebase'
import StepperSection from './StepperSection'
import SongCard from './SongCard'

const getSongData = async (token, id) => {
  const data = await axios.get("https://api.spotify.com/v1/tracks/" + id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

const getSongLink = async (type, id) => {
  const songRef = collection(db, 'songs');
  const q = query(songRef, where(type, '==', id));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs;
}


const SongCards = (props) => {
  const [originals, setOriginals] = useState([])
  const [derivates, setDerivates] = useState([])
  const [links, setLinks] = useState([])
  const originSongs = []
  const productSongs = []
  const Links = []

  useEffect(() => {
    props.activeSongs.forEach(link => {
      setOriginals(originals.concat([
        <SongCard
          token={props.token}
          id={link} />
      ]))
      getSongLink(
        'source',
        link
      ).then(googleData => {
        if (googleData.length !== 0) {
          let productId = googleData[0].data().product
          setDerivates(derivates.concat([
            <SongCard
              token={props.token}
              id={productId} />
          ]))
          setLinks(links.concat([
            <Xarrow 
              key={link + productId}
              start={'conn_' + link} 
              end={'conn_' + productId}
              showHead={false}
              color={'#eab915'}
              />
          ]))
        }
      })
    })
    // setOriginals(props.activeSongs.map(link => {
    //   return(
    //     <SongCard 
    //       token={props.token}
    //       id={link} />
    //   )
    // }))

    // (props.activeSongs.map(link => {  
    //   getSongLink(
    //     'source',
    //     link
    //   ).then(googleData => {
    //     if (googleData.length !== 0) {
    //       setDerivates(derivates.concat[<SongCard 
    //         token={props.token}
    //         id={googleData[0].data().product} />])


    //     } else {
    //       console.log('Not found in db')
    //       return (
    //         <div>?</div>
    //       )
    //     }
    //   })
    // }))

  }, [props])



  // const productSongs = props.activeSongs.map(link => {

  // })

  // return(
  //   <Xarrow 
  //     key={link.id}
  //     start={link}
  //     end={googleData[0].data().product}
  //     showHead={false}
  //     color={'#eab915'} 
  //   />
  // )

  // linksJSON.forEach((link) => {
  //   Links.push(
  //     <Xarrow key={link.id} start={link.from.toString()} end={link.to.toString()} showHead={false} color={'#eab915'} />
  //   )
  // });
  // if (props.activeSongs.length > 0) {
  return (
    <Flex flex={1}
      id='SongCards'
      overflowX={'hidden'}
      maxH={'90vh'}
      bg={'blackAlpha.900'}>

      <Container
        id={'origins'}
        maxH={'90vh'}
        p={5}
        boxSizing='content-box'>
        {originals}

      </Container>
      <Container
        id={'products'}
        p={5}
        boxSizing='content-box'>
        {derivates}
      </Container>
      {links}
    </Flex>
  )
}

const Connector = (props) => {
  if (props.activeSongs.length < 1) {
    return (
      <Flex flex={1} p={0} flexDirection={'column'}>
        <StepperSection />
        <Flex h='80vh'>
          <Spacer />
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.100'
              color='teal'
              size='xl'
            />
          </Center>
          <Spacer />
        </Flex>
      </Flex >
    )
  } else {
    return (
      <Flex flex={1} p={0} flexDirection={'column'}>
        <StepperSection />
        <SongCards
          token={props.token}
          activeSongs={props.activeSongs}
        />
      </Flex >
    )
  }
}

export default Connector;