import React from 'react';
import axios from 'axios';

import { Container, Box, SimpleGrid, Text, Image, Button, Input, InputGroup, InputRightElement, Flex, Spacer, Center } from '@chakra-ui/react'


const RenderSongs = (props) => {
    return props.songs.map(song => {
        let songIsActive = props.activeSongs.indexOf(song.id) !== -1
        return (
            <Box
                onClick={() => {
                    if (songIsActive) {
                        if(props.activeSongs.length > 1){
                            console.log(props.activeSongs.length)
                            props.activeSongs.splice(
                                props.activeSongs.indexOf(song.id), 1
                            )
                            props.setActiveSongs(props.setActiveSongs)
                        }else{
                            props.setActiveSongs([])
                        }
                        
                    } else {
                        props.setActiveSongs(props.activeSongs.concat(song.id))
                    }
                }}
                w='90%'
                key={song.id}
                id={song.id}
                marginBottom={3}
                borderRadius={10}
                color={'blackAlpha.900'}
                border={'1px solid white'}
                bg={(songIsActive) ? 'grey' : 'whitesmoke'}
            >
                <Flex p={2}>
                    <Center>
                        <Image
                            borderRadius={5}
                            boxSize='50px'
                            alt="SoundzLike.com"
                            fallbackSrc='https://via.placeholder.com/70'
                            src={song.album.images.length
                                ?
                                song.album.images[0].url
                                :
                                ''
                            }
                        />
                    </Center>
                    <Box marginLeft={5} flex='1'>
                        <Text fontSize='sm'>{song.artists[0].name} - {song.name}</Text>
                        <Text fontSize='xs'>{song.album.name}</Text>
                    </Box>
                </Flex>
            </Box>
        )
    })
}

const SongList = (props) => {
    const searchSongs = async (e) => {
        console.log(props.token)
        e.preventDefault()
        const { data } = await axios.get("https://api.spotify.com/v1/search", {
            headers: {
                Authorization: `Bearer ${props.token}`
            },
            params: {
                q: props.searchKey,
                type: "track"
            }
        })
        console.log(data)
        props.setSongs(data.tracks.items)
    }

    const clearSongs = () => {
        props.setSongs([])
    }

    return (
        <SimpleGrid
            minChildWidth='95%'
            columns={2}
            color={'blackAlpha.900'}
            overflow={'hidden'}
            maxH={'90vh'}
        >
            <Center p={2} bg={'white'}>
                <form style={{ "width": "100%" }} onSubmit={searchSongs}>
                    <InputGroup size='lg' >
                        <Input placeholder='La macarena?' bg={'whiteAlpha.900'} onChange={e => props.setSearchKey(e.target.value)} />
                        <InputRightElement width='5rem'>
                            <Button colorScheme='teal' size='sm' type={"submit"}>Search</Button>
                        </InputRightElement>
                    </InputGroup>
                </form>
            </Center>
            {props.songs.length > 0
                ?
                <Container
                    overflowY={'scroll'}
                    maxH={'80vh'}
                    p={5}
                    paddingRight={17}
                    paddingBottom={2000}
                    boxSizing='content-box'>
                    <RenderSongs activeSongs={props.activeSongs} setActiveSongs={props.setActiveSongs} songs={props.songs} />
                    <Box
                        w='90%'
                        marginBottom={3}
                        borderRadius={10}
                        border={'1px solid white'}
                        bg={'whitesmoke'}
                        color={'blackAlpha.900'}
                    >
                        <Center p={5}>
                            <Button colorScheme='red' size='lg' onClick={clearSongs}>Clear search</Button>
                        </Center>
                    </Box>
                </Container>
                :
                <Center h={'90vh'} p={5} bg={'black'}>
                    <Text fontSize='sm' color={'white'}> Search for something! </Text>
                </Center>
            }

        </SimpleGrid>
    )
}

export default SongList;