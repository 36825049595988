
import {
    Box,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
} from '@chakra-ui/react'

const steps = [
    { title: 'Source', description: 'Search for a song!' },
    { title: 'Product', description: 'Does it sound like these?' },
    { title: 'Proof', description: 'Add or edit the proof.' },
]

const StepperSection = () => {
    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    })

    return (
        <Stepper bg={'white'} paddingX={5} gap={0} h={'64px'} colorScheme='teal' size='sm' index={activeStep}>
            {steps.map((step, index) => (
                <Step key={index} onClick={() => setActiveStep(index)}>
                    <StepIndicator>
                        <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                        />
                    </StepIndicator>

                    <Box flexShrink='0'>
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                </Step>
            ))}
        </Stepper>
    )
}

export default StepperSection;
