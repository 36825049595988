
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Kbd, Text, Image, Button, Link, Icon, Input, InputGroup, InputRightElement, Flex, Spacer, Center } from '@chakra-ui/react'
import { SlSocialSpotify } from 'react-icons/sl'

import Logo from "./../assets/logo.png";

const Header = (props) => {
  const CLIENT_ID = process.env.REACT_APP_SPOTIFY_APP_ID
  const REDIRECT_URI = process.env.REACT_APP_SPOTIFY_REDIRECT_URI
  const AUTH_ENDPOINT = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT
  const RESPONSE_TYPE = "token"

  const logout = () => {
    props.setToken("")
    window.localStorage.removeItem("spotifyTokenHash")
    window.localStorage.removeItem("spotifyTokenTime")
  }

  useEffect(() => {
    const hash = window.location.hash
    let tokenHash = window.localStorage.getItem("spotifyTokenHash")
    let tokenTime = window.localStorage.getItem("spotifyTokenTime")

    if (tokenTime !== null && ((parseInt(tokenTime) + 1200000) < new Date().getTime())) {
      console.log('Expired token')
      tokenHash = null
      tokenTime = null
      props.setToken("")
      window.localStorage.removeItem("spotifyTokenHash")
      window.localStorage.removeItem("spotifyTokenTime")
    } else {
      console.log(tokenTime)
      console.log('Stored token still valid for ' + (new Date().getTime() - tokenTime) / 1000 + 's')
    }

    if (!tokenHash && hash) {
      tokenHash = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

      window.location.hash = ""
      window.localStorage.setItem("spotifyTokenHash", tokenHash)
      window.localStorage.setItem("spotifyTokenTime", new Date().getTime())
    } else {
      props.setToken(tokenHash)
    }

  }, [])


  return (
    <Flex flex={1} bg={'teal'} color={'white'}>
      <Center h='80px'>
        <Image
          borderRadius='full'
          boxSize='70px'
          src={Logo}
          marginLeft={2}
          alt="SoundzLike.com"
          fallbackSrc='https://via.placeholder.com/70'
        />
      </Center>
      <Spacer />
      <Center h='80px'>
        <Text fontSize={'3xl'}>SoundzLike.com</Text>
      </Center>
      <Spacer />
      <Center w='150px' paddingRight={5}>
        {!props.token ?
          <Link href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`}>
            <Button colorScheme='green' leftIcon={<Icon as={SlSocialSpotify} />} size='sm'>Login to Spotify</Button>
          </Link>
          :
          <Button colorScheme='green' leftIcon={<Icon as={SlSocialSpotify} />} size='sm' onClick={logout}>Logout</Button>
        }
      </Center>
    </Flex>
  )
}

export default Header;